<template>
  <div class="cartpage-container">
    <PageHeader
      heading="Dine Valg"
      backButtonText="Fortsett å plukke"
      :backButtonFunction="() => $router.push('/tilvalg')"
      :showBackButton="$store.state.cart.all.length !== 0"
    />
    <CartContents />
  </div>
</template>

<script>
import CartContents from '@/components/CartContents.vue';
import PageHeader from '@/components/PageHeader.vue';

export default {
  components: {
    CartContents,
    PageHeader,
  },
};
</script>

<style lang="scss" scoped>
.cartpage-container {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  width: 80%;
  max-width: 560px;
  margin: 0 auto;
}
</style>
