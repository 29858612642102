<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :height="props.height"
    :width="props.width"
    :aria-hidden="props.decorative"
    aria-labelledby="title"
  >
    <title id="title">{{ props.title }}</title>
    <path
      :fill="props.mainColor"
      fill-rule="evenodd"
      d="M17 11a1 1 0 0 1 0 2h-4v4a1 1 0 0 1-2 0v-4H7a1 1 0 0 1 0-2h4V7a1 1 0 0 1 2 0v4h4z"
    ></path>
  </svg>
</template>

<script>
import iconProps from '@/mixins/iconProps';

export default {
  mixins: [iconProps],
};
</script>
