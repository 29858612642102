<template>
  <div class="page-header">
    <div>
      <h4 class="h4">{{ subHeading }}</h4>
      <h1 class="h1">{{ heading }}</h1>
    </div>
    <BackButton v-if="showBackButton" @click="backButtonFunction">{{ backButtonText }}</BackButton>
  </div>
</template>

<script>
import BackButton from '@/components/elements/BackButton.vue';

export default {
  components: {
    BackButton,
  },

  props: {
    heading: {
      type: String,
      required: true,
    },
    subHeading: {
      type: String,
      required: false,
    },
    backButtonText: {
      type: String,
      required: false,
      default: 'Tilbake',
    },
    showBackButton: {
      type: Boolean,
      required: false,
      default: true,
    },
    backButtonFunction: {
      type: Function,
      required: false,
      default: () => window.history.back(),
    },
  },
};
</script>

<style lang="scss" scoped>
.page-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
</style>
