<template functional>
  <button @click="listeners['click']" class="back-button">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="20" class="icon-arrow">
      <circle cx="12" cy="12" r="10" fill="none"></circle>
      <path
        fill="currentcolor"
        d="M12 10h5a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-5v2a1 1 0 0 1-1.7.7l-4-4a1 1 0 0 1 0-1.4l4-4A1 1 0 0 1 12 8v2z"
      ></path>
    </svg>
    <slot></slot>
  </button>
</template>

<script>
export default {
  //
};
</script>

<style lang="scss" scoped>
.back-button {
  display: flex;
  align-items: center;
}

.back-button:hover {
  .icon-arrow {
    transform: translateX(-3px);
    transition: all 0.2s ease;
  }
}

.icon-arrow {
  margin-right: 1rem;
}
</style>
