<template functional>
  <div class="total-container">
    <div class="price-row">
      <div class="description">Total</div>
      <div class="amount">{{ props.total | currencyNOK }}</div>
    </div>
    <div class="tax-row">eks. mva.</div>
  </div>
</template>

<script>
export default {
  props: {
    total: {
      type: Number,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.total-container {
  display: inline-flex;
  flex-direction: column;
}
.price-row {
  display: flex;
  align-items: flex-end;
  font-weight: 600;

  .amount {
    font-size: $font-size-3;
    margin-left: $spacing-100;
  }
  .description {
    font-size: $font-size-2;
    color: $color-gray-400;
  }
}
.tax-row {
  align-self: flex-end;
  margin-right: 4px;
  font-size: $font-size--2;
  color: $color-gray-400;
}
</style>
