<template>
  <div class="qty-container">
    <button
      class="qty-container__btn"
      :disabled="!allowNegative && quantity == 1"
      @click="decrease"
    >
      <RemoveIcon />
    </button>
    <div class="qty-container__qty">{{ quantity }}</div>
    <button class="qty-container__btn" @click="increase">
      <AddIcon />
    </button>
  </div>
</template>

<script>
import RemoveIcon from '@/components/graphic/RemoveIcon.vue';
import AddIcon from '@/components/graphic/AddIcon.vue';

export default {
  components: {
    RemoveIcon,
    AddIcon,
  },

  props: {
    quantity: {
      type: Number,
      required: true,
    },
    allowNegative: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    disableDecrese() {
      return this.quantity <= 1 && !this.allowNegative;
    },
  },

  methods: {
    decrease() {
      if (this.quantity <= -100) return;
      this.emitQuantity(this.quantity - 1);
    },
    increase() {
      if (this.quantity >= 100) return;
      this.emitQuantity(this.quantity + 1);
    },
    emitQuantity(newQuantity) {
      this.$emit('newQuantity', newQuantity);
    },
  },
};
</script>

<style lang="scss" scoped>
.qty-container {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100px;
}

.qty-container__btn {
  display: inline-flex;
  align-items: center;
  // border: 2px solid $color-gray-100;
  // background-color: $color-gray-200;
  // border-radius: $border-radius-small;
}

.qty-container__qty {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: $font-size-2;
}
</style>
