<template>
  <div class="empty" v-if="$store.state.cart.all.length === 0">
    <EmptyCart />
    <div class="add-product-button">
      <SecondaryButton @click="$router.push('/tilvalg')">Legg til et produkt</SecondaryButton>
    </div>
  </div>
  <div class="not-empty" v-else>
    <CartItem v-for="item in cart" :key="item._key">
      <template #remove>
        <button v-tooltip="'Slett'" @click="removeItem(item._key)">
          <CloseIcon />
        </button>
      </template>
      <template #name>{{ productName(item._key) }}</template>
      <template #number>
        <ExternalLink :url="fdvURL(productNumber(item._key))" :text="productNumber(item._key)" />
      </template>
      <template #quantity>
        <QuantitySelector
          @newQuantity="updateQuantity(item._key, item.standard, $event)"
          :quantity="item.quantity"
          :allowNegative="item.standard"
        />
      </template>
      <template #price>
        {{ (productPrice(item._key) * item.quantity) | currencyNOK }}
      </template>
    </CartItem>
    <div class="total">
      <TotalPrice :total="cartTotal" />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import fdvURL from '@/mixins/fdvURL';
import CartItem from '@/components/elements/CartItem.vue';
import TotalPrice from '@/components/elements/TotalPrice.vue';
import QuantitySelector from '@/components/elements/QuantitySelector.vue';
import EmptyCart from '@/components/graphic/EmptyCart.vue';
import CloseIcon from '@/components/graphic/CloseIcon.vue';
import ExternalLink from '@/components/elements/ExternalLink.vue';
import SecondaryButton from '@/components/elements/SecondaryButton.vue';

export default {
  components: {
    CartItem,
    TotalPrice,
    QuantitySelector,
    EmptyCart,
    CloseIcon,
    ExternalLink,
    SecondaryButton,
  },

  mixins: [fdvURL],

  computed: {
    ...mapState({
      cart: state => state.cart.all,
    }),
    ...mapGetters({
      productName: 'products/getName',
      productNumber: 'products/getNumber',
      productPrice: 'products/calculatePrice',
      cartTotal: 'cart/getCartTotal',
    }),
  },

  methods: {
    updateQuantity(productId, standard, newQuantity) {
      this.$store.dispatch('cart/updateQuantity', {
        productId,
        newQuantity,
        standard,
      });
    },
    removeItem(productId) {
      this.$store.dispatch('cart/removeItemFromCart', productId);
    },
  },
};
</script>

<style lang="scss" scoped>
.not-empty {
  display: flex;
  flex-direction: column;
  margin-top: $spacing-300;

  .total {
    margin-top: $spacing-200;
    align-self: flex-end;
  }
}
.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: $spacing-600;

  .add-product-button {
    margin-top: $spacing-300;
  }
}
</style>
